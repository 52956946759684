import React, { PureComponent } from "react";
import "./index.scss";

class Footer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div className="footer-wrapper">
        <div className="footer-links-section">
          <div className="footer-link">About us</div>
          <div className="footer-link">FAQ</div>
          <div className="footer-link">Estate agents</div>
          <div className="footer-link">Latest news</div>
          <div className="footer-link">Sitemap</div>
          <div className="footer-link">Privacy</div>
        </div>
        <div className="copyright-section">
          ©Crown copyright material reproduced with permission of Land Registry
          and Registers of Scotland.
        </div>
        <div className="copyright-section">
          ©Copyright Landmark Analytics Limited
        </div>
      </div>
    );
  }
}

Footer.propTypes = {};

export default Footer;
