import React from "react";
import "./index.scss";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { FaSearch } from "react-icons/fa";
import { TiMail, TiKeyOutline } from "react-icons/ti";
import { Row, Col } from "reactstrap";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Footer from "../Footer/index";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(0)
  },
  input: {
    display: "none"
  }
}));

const Home = () => {
  const classes = useStyles();
  return (
    <div className="home-wrapper">
      <div className="home-top-section">
        <div className="home-header-text">HMORegister</div>
        <div className="home-header-links">
          <div className="home-link">Logout</div>
          <div className="home-link">My Account</div>
        </div>
      </div>
      <div className="bg-search">
        <div className="bg-search-text">
          <div className="top-text"> Search sold prices & valuations </div>
          <div className="second-title">for any property in the UK </div>
          <div className="search-section">
            <div className="main-input">
              <Typeahead
                labelKey={option => `${option.firstName} ${option.lastName}`}
                options={[]}
                placeholder="Enter a postcode or address"
                style={{ width: "70%" }}
              />
            </div>
            <div className="search-icon">
              <FaSearch
                color="#64ac2f"
                size={40}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div className="buttons-wrapper">
            <div className="first-button">Sold prices and valuations</div>
            <div className="second-button">For sale</div>
            <div className="third-button">To rent</div>
          </div>
        </div>
      </div>
      <div className="home-cards">
        <div className="card-1">
          <div className="card-title">
            <TiMail size="50" color="#64ac2f" />
            <div className="logo-text">SELLING YOUR HOME?</div>
          </div>
          <div className="card-text">
            Get an estate agent's view on your home's value now.
          </div>
        </div>
        <div className="card-2">
          <div className="card-title">
            <TiKeyOutline size="50" color="#64ac2f" />
            <div className="logo-text">SELLING YOUR HOME?</div>
          </div>
          <div className="card-text">
            Get an estate agent's view on your home's value now.
          </div>
        </div>
      </div>
      <div className="more-to-offer-title">MORE FROM MOUSEPRICE</div>
      <Row className="offer-buttons-section">
        <Col className="offer-button" lg="3" md="3" sm="12">
          <Button variant="outlined" className={classes.button}>
            What is my house worth?
          </Button>
        </Col>
        <Col className="offer-button" lg="3" md="3" sm="12">
          <Button variant="outlined" className={classes.button}>
            Selling yout home
          </Button>
        </Col>
        <Col className="offer-button" lg="3" md="3" sm="12">
          <Button variant="outlined" className={classes.button}>
            About Stamp Duty
          </Button>
        </Col>
        <Col className="offer-button" lg="3" md="3" sm="12">
          <Button variant="outlined" className={classes.button}>
            First time buyer?
          </Button>
        </Col>
      </Row>
      <Footer />
    </div>
  );
};

Home.propTypes = {};

export default Home;
