import React, { PureComponent } from "react";
import "./index.scss";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { FaSearch } from "react-icons/fa";

class Header extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div className="header-wrapper">
        <div className="header-top-section">
          <div className="header-header-text">Mouseprice</div>
          <div className="header-header-links">
            <div className="header-link">LOGOUT</div>
            <div className="header-link">MY ACCOUNT</div>
          </div>
        </div>
        <div className="bg-search">
          <div className="bg-search-text">
            <div className="search-section">
              <div className="main-input">
                <Typeahead
                  labelKey={option => `${option.firstName} ${option.lastName}`}
                  options={[
                    { firstName: "Art", lastName: "Blakey" },
                    { firstName: "Jimmy", lastName: "Cobb" },
                    { firstName: "Elvin", lastName: "Jones" },
                    { firstName: "Max", lastName: "Roach" },
                    { firstName: "Tony", lastName: "Williams" }
                  ]}
                  placeholder="Enter a postcode or address"
                  style={{ width: "70%" }}
                />
              </div>
              <div className="search-icon">
                <FaSearch
                  color="#64ac2f"
                  size={40}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
            <div className="buttons-wrapper">
              <div className="first-button">Sold prices and valuations</div>
              <div className="second-button">For sale</div>
              <div className="third-button">To rent</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Header.propTypes = {};

export default Header;
