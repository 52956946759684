import React from "react";
import "./index.scss";

const SaleHomeDetails = ({
  image,
  price,
  address,
  property,
  time,
  company
}) => {
  return (
    <div className="home-details-wrapper">
      <div className="home-details-image">
        <img src={image} className="home-image" alt="sale-home"></img>
      </div>
      <div className="home-details-more">
        <div className="home-price">{price}</div>
        <div className="home-address">{address}</div>
        <div className="properties">
          <div className="propertie">{property}</div>
          <div className="time-buy">{time}</div>
        </div>
        <div className="company-from">
          <div className="company">{company}</div>
          <div className="home-place">Map</div>
        </div>
      </div>
    </div>
  );
};

SaleHomeDetails.propTypes = {};

export default SaleHomeDetails;
