import React from "react";
import "./App.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./Components/Home/index";
import ForSale from "./Components/ForSale/index";
import SellHome from "./Components/SellHome/index"

function App() {
  return (
    <Router>
      <div className="App-wrapper">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/forsale" component={ForSale} />
          <Route path="/sellHome" component={SellHome} />

        </Switch>
      </div>
    </Router>
  );
}

export default App;
