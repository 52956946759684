import React from "react";
import "./index.scss";

const HomeNews = ({ title, news }) => {
  return (
    <div className="home-news">
      <div className="home-news-title">{title}</div>
      <div className="home-news-more">{news}</div>
    </div>
  );
};

HomeNews.propTypes = {};

export default HomeNews;
