import React, { PureComponent } from 'react';
import './index.scss';

class SellHome extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div className="sell-home-wrapper">
                 <div className="header-top-section">
          <div className="header-header-text">Mouseprice</div>
          <div className="header-header-links">
            <div className="header-link">LOGOUT</div>
            <div className="header-link">MY ACCOUNT</div>
          </div>
        </div>
            </div>
        );
    }
}

SellHome.propTypes = {

};

export default SellHome;
