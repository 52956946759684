import React, { PureComponent } from "react";
import "./index.scss";
import Header from "../Header/index";
import { Row, Col } from "reactstrap";
import "antd/dist/antd.css";
import { Menu, Dropdown, Button, Icon, Pagination } from "antd";
import SaleHomeDetails from "../SaleHomeDetails/index";
import HomeNews from "../HomeNews";
import { TiLocationOutline } from "react-icons/ti";
import Footer from "../Footer/index"

const menu = (
  <Menu>
    <Menu.Item key="1">
      <Icon type="user" />
      1st menu item
    </Menu.Item>
    <Menu.Item key="2">
      <Icon type="user" />
      2nd menu item
    </Menu.Item>
    <Menu.Item key="3">
      <Icon type="user" />
      3rd item
    </Menu.Item>
  </Menu>
);

class ForSale extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div className="forsale-wrapper">
        <Header />
        <div className="forsale-container">
          <Row>
            <Col lg="8" md="12" sm="12" className="forsale-left">
              <div className="forsale-left-top">
                <div className="dropdown-section">
                  <Dropdown overlay={menu}>
                    <Button>
                      ANY PRICE <Icon type="down" />
                    </Button>
                  </Dropdown>
                  <Dropdown overlay={menu}>
                    <Button>
                      ANY PRICE <Icon type="down" />
                    </Button>
                  </Dropdown>
                  <Dropdown overlay={menu}>
                    <Button>
                      ANY TYPE <Icon type="down" />
                    </Button>
                  </Dropdown>
                  <Dropdown overlay={menu}>
                    <Button>
                      ANY BEDS <Icon type="down" />
                    </Button>
                  </Dropdown>
                </div>
                <div className="sortby-text">Sort By:</div>
                <div className="sortby-type">
                  <div className="sortby-link-1">Hightest rice</div>
                  <div className="sortby-link-2">Lowest price</div>
                  <div className="sortby-link-3">Newest</div>
                  <div className="sortby-link-4">Oldest</div>
                  <div className="sortby-link-5">Most reduced price</div>
                </div>
              </div>
              <SaleHomeDetails
                image={
                  "https://ap.rdcpix.com/4170392521/cc46bb8e561d62ddc44d28a0d1cf9c45l-m0od-w480_h360.jpg"
                }
                price={"£100.00"}
                address={"Tirane,Sheshi Willson..."}
                property={"1 Bed Flat"}
                time={"10 days ago"}
                company={"Horizont"}
              />
              <SaleHomeDetails
                image={
                  "https://ap.rdcpix.com/4170392521/cc46bb8e561d62ddc44d28a0d1cf9c45l-m0od-w480_h360.jpg"
                }
                price={"£100.00"}
                address={"Tirane,Sheshi Willson..."}
                property={"1 Bed Flat"}
                time={"10 days ago"}
                company={"Horizont"}
              />
              <SaleHomeDetails
                image={
                  "https://ap.rdcpix.com/4170392521/cc46bb8e561d62ddc44d28a0d1cf9c45l-m0od-w480_h360.jpg"
                }
                price={"£100.00"}
                address={"Tirane,Sheshi Willson..."}
                property={"1 Bed Flat"}
                time={"10 days ago"}
                company={"Horizont"}
              />
              <div className="forsale-left-pagnation">
                <Pagination defaultCurrent={1} total={50} />
              </div>
            </Col>
            <Col lg="4" md="12" sm="12" className="forsale-right">
            <div className="card">
            <div className="card-title">
              <TiLocationOutline size="50" color="#64ac2f" />
              <div className="logo-text">SELLING YOUR HOME?</div>
            </div>
            <div className="card-text">Get an estate agent's view on your home's value now.</div>
          </div>
              <HomeNews
                title={"Home news title"}
                news={
                  "Home news textssssssssssssssssssssssssssss ddddddddddddd ddddddddddddddddddddddddd dddddddddddddddddd ddddddddddddddddddd dddddddddddddd"
                }
              />
            </Col>
          </Row>
        </div>
        <Footer/>
      </div>
    );
  }
}

ForSale.propTypes = {};

export default ForSale;
